import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import getAuthorByName from '../../repositories/getAuthorByName';

const Author = ({ name }) => {
  const author = getAuthorByName(name);

  return (
    <>
      <figure className="author-image">
        <Link
          to={author.fields.slug}
          className="img"
          style={{
            backgroundImage: `url(${author.frontmatter.picture})`,
          }}
        >
          <span className="hidden">{author.frontmatter.title}’s Picture</span>
        </Link>
      </figure>
      <section className="author">
        <h4>
          <span>{author.frontmatter.title}</span>
        </h4>

        <p>{author.frontmatter.description}</p>

        <div className="author-meta">
          <span className="author-location icon-location">
            {author.frontmatter.location}
          </span>
        </div>
      </section>
    </>
  );
};

Author.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Author;
