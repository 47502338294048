import React, { useState } from 'react';
import { navigate } from 'gatsby-link';

const SubscribeForm = () => {
  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&');
  }

  // eslint-disable-next-line
  const [isValidated, setValidatedState] = useState(false);
  const [formFields, setFormFields] = useState({});

  const handleChange = (e) => {
    const fields = formFields;
    fields[e.target.name] = e.target.value;
    setFormFields(fields);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...formFields,
        isValidated,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error));
  };

  return (
    <form
      name="subscribe"
      method="post"
      action="/subscribe/thank-you/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="subscribe" />
      <div hidden>
        <label>
          Don’t fill this out:{' '}
          <input name="bot-field" onChange={handleChange} />
        </label>
      </div>
      <div className="form-group">
        <input
          className="subscribe-email"
          type="email"
          name="email"
          onChange={handleChange}
          id="email"
          required={true}
        />
      </div>
      <button type="submit">Subscribe</button>
      {/* {{#if error}}
          <p class="main-error">{{{error.message}}}</p>
      {{/if}} */}
    </form>
  );
};

export default SubscribeForm;
