import { useStaticQuery, graphql } from 'gatsby';

const getAuthorByName = (name) => {
  const {
    allMarkdownRemark: { edges: authors },
  } = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "author" } } }
      ) {
        edges {
          node {
            frontmatter {
              coverImage
              picture
              title
              description
              location
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `);

  return authors
    .map((author) => author.node)
    .find((author) => {
      return author.frontmatter.title === name;
    });
};

export default getAuthorByName;
