import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import getAuthorByName from '../../repositories/getAuthorByName';

const AuthorSnippet = ({ name }) => {
  const author = getAuthorByName(name);

  return (
    <>
      <img
        className="author-thumb"
        src={author.frontmatter.picture}
        alt={name}
        nopin="nopin"
      />
      <Link to={author.fields.slug}>{name}</Link>
    </>
  );
};

AuthorSnippet.propTypes = {
  name: PropTypes.string.isRequired,
};

export default AuthorSnippet;
