import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import { Location } from '@reach/router';
import classNames from 'classnames';
import { Disqus, CommentCount } from 'gatsby-plugin-disqus';

import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import Tags from '../components/Blog/Tags';
import {
  ApplicationContext,
  nullContext,
} from '../components/Contexts/ApplicationContext';
import querystring from 'querystring';
import SubscribeForm from '../components/Blog/SubscribeForm';
import Author from '../components/Blog/Author';
import AuthorSnippet from '../components/BlogRoll/AuthorSnippet';
import { DISQUS_CONFIG, SITE_URL } from '../helpers/constants';

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
  post,
  next,
  prev,
  preview = false,
}) => {
  const PostContent = contentComponent || Content;
  let context = useContext(ApplicationContext);
  if (preview) {
    context = nullContext;
  }
  const coverImage = post.frontmatter.coverImage;
  const isPublic = !preview;

  return (
    <>
      {helmet || ''}
      <Helmet>
        <body className={context.actions.getBodyClassNames('post-template')} />
      </Helmet>
      {isPublic && (
        <header
          className={classNames('main-header', 'post-head', {
            'no-cover': !coverImage,
          })}
          style={{ backgroundImage: coverImage ? `url(${coverImage})` : null }}
        >
          <nav
            className={classNames('main-nav', 'clearfix', {
              overlay: coverImage,
            })}
          >
            <a
              href="#menu"
              className="menu-button icon-menu"
              onClick={context.actions.toggleMenu}
            >
              <span className="word">Menu</span>
            </a>
          </nav>
        </header>
      )}
      <main className="content" role="main">
        <article className="post">
          <header className="post-header">
            <h1 className="post-title">{title}</h1>
            <section className="post-meta">
              {isPublic ? (
                <AuthorSnippet name={post.frontmatter.author} />
              ) : (
                <span>{post.frontmatter.author}</span>
              )}
              {tags && <Tags tags={tags} prefix=" on " />}
              {' - '}
              <time className="post-date" dateTime={post.frontmatter.date}>
                {post.frontmatter.date}
              </time>
              {post.fields && ' - '}
              {post.fields && (
                <Link
                  className="post-date"
                  to={`${post.fields.slug}#disqus_thread`}
                >
                  <CommentCount
                    config={DISQUS_CONFIG({
                      id: post.id,
                      title: post.frontmatter.title,
                      pathname: `/${post.fields.slug}/`,
                    })}
                    placeholder="Comments"
                  />
                </Link>
              )}
            </section>
          </header>

          <section className="post-content">
            <PostContent content={content} />
          </section>

          {isPublic && (
            <footer className="post-footer">
              {/* {{!-- Everything inside the #author tags pulls data from the author --}} */}

              {post.frontmatter.author && (
                <Author name={post.frontmatter.author} />
              )}

              <section className="share">
                <h4>Share this post</h4>
                <Location>
                  {({ location }) => {
                    return (
                      <>
                        <a
                          className="icon-twitter"
                          href={`https://twitter.com/intent/tweet?${querystring.stringify(
                            { text: title }
                          )}&amp;${querystring.stringify({
                            url: `${SITE_URL}${location.pathname}`,
                          })}`}
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(
                              e.target.href,
                              'twitter-share',
                              'width=550,height=235'
                            );
                          }}
                        >
                          <span className="hidden">Twitter</span>
                        </a>
                        <a
                          className="icon-facebook"
                          href={`https://www.facebook.com/sharer/sharer.php?${querystring.stringify(
                            { u: `${SITE_URL}${location.pathname}` }
                          )}`}
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(
                              e.target.href,
                              'facebook-share',
                              'width=580,height=296'
                            );
                          }}
                        >
                          <span className="hidden">Facebook</span>
                        </a>
                      </>
                    );
                  }}
                </Location>
              </section>

              {/* {{!-- Email subscribe form at the bottom of the page --}} */}

              <section className="gh-subscribe">
                <h3 className="gh-subscribe-title">Subscribe to joshwhatk</h3>
                <p>Get the latest posts delivered right to your inbox.</p>
                {/* {{subscribe_form placeholder="Your email address"}} */}
                <SubscribeForm />
              </section>

              {/* {{!-- Disqus Main Code --}} */}
              <Disqus
                config={DISQUS_CONFIG({
                  id: post.id,
                  title: post.frontmatter.title,
                  pathname: `/${post.fields.slug}/`,
                })}
              />
            </footer>
          )}
        </article>
      </main>

      {/* {{!-- Links to Previous/Next posts --}} */}
      <aside className="read-next">
        {next && (
          <Link
            to={next.fields.slug}
            className={classNames('read-next-story', {
              'no-cover': !next.frontmatter.coverImage,
            })}
            style={{
              backgroundImage: next.frontmatter.coverImage
                ? `url(${next.frontmatter.coverImage})`
                : null,
            }}
          >
            <section className="post">
              <h2>{next.frontmatter.title}</h2>
              <p>{next.excerpt}</p>
            </section>
          </Link>
        )}
        {prev && (
          <Link
            to={prev.fields.slug}
            className={classNames('read-next-story', 'prev', {
              'no-cover': !prev.frontmatter.coverImage,
            })}
            style={{
              backgroundImage: prev.frontmatter.coverImage
                ? `url(${prev.frontmatter.coverImage})`
                : null,
            }}
          >
            <section className="post">
              <h2>{prev.frontmatter.title}</h2>
              <p>{prev.excerpt}</p>
            </section>
          </Link>
        )}
      </aside>
    </>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  post: PropTypes.shape({
    frontmatter: PropTypes.object.isRequired,
  }),
  next: PropTypes.object,
  prev: PropTypes.object,
};

const BlogPost = ({ data, pageContext }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | joshwhatk">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        post={post}
        next={pageContext.next}
        prev={pageContext.prev}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        coverImage
        title
        description
        tags {
          tag
        }
        author
      }
    }
  }
`;
