import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { kebabCase } from 'lodash';

const Tags = ({ tags, prefix }) => {
  return (
    <span className="Tags">
      <span className="Tags-prefix">{prefix}</span>
      {tags.map(({ tag }, index) => {
        const lowercaseTag = tag.toLowerCase();
        return (
          <span className="Tags-tag" key={`${lowercaseTag}_tag`}>
            {index !== 0 && ', '}
            <Link to={`/tags/${kebabCase(lowercaseTag)}/`}>{tag}</Link>
          </span>
        );
      })}
    </span>
  );
};

Tags.propTypes = {
  tags: PropTypes.array,
  prefix: PropTypes.string,
};

export default Tags;
